html,
body {
  font-family:
    "Cabin",
    "Noto Sans JP",
    "Hiragino Sans",
    "ヒラギノ角ゴ ProN",
    Hiragino Kaku Gothic ProN,
    "メイリオ",
    Meiryo,
    "游ゴシック Medium",
    YuGothic,
    YuGothicM,
    sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #21241f;
  line-height: 1.7;
}
header,
footer,
div,
section,
article,
nav,
a,
p,
span,
ul,
ol,
li,
dl,
dt,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
img,
dl,
dt,
dd,
form,
input,
textarea {
  box-sizing: border-box;
}
::selection {
  background: #ddd;
}
::-moz-selection {
  background: #ddd;
}
a {
  color: #21241f;
  transition:
    opacity 0.3s,
    color 0.3s;
  text-decoration: none;
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
a:hover {
  opacity: 0.7;
  cursor: pointer;
}
img,
picture {
  max-width: 100%;
  display: block;
}
.clear {
  clear: both;
}
.overflow {
  overflow: hidden;
}

.green {
  color: #036835;
}

.common-box {
  background: #fff;
  border-radius: 1.2rem;
  padding: 4rem 8rem;
}
.common-box p {
  margin: 1em 0;
}
.common-box h2 {
  font-size: 2rem;
  margin: 3rem 0 1.5rem;
}
.common-box ul,
.common-box ol {
  margin: 1em 0;
}
.common-box ul {
  list-style: disc;
}
.common-box ul li,
.common-box ol li {
  margin-left: 1.4em;
  padding-left: 0.1em;
}
.common-box ul li + li,
.common-box ol li + li {
  margin-top: 0.5em;
}

ul.pankuzu {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.4rem;
  opacity: 0.5;
  margin-bottom: 2.5rem;
}
ul.pankuzu a {
  text-decoration: underline;
}
ul.pankuzu li {
  display: block;
}
ul.pankuzu li:not(:last-child)::after {
  content: ">";
  margin: 0 0.6em;
}

a.bnr-request {
  display: block;
  overflow: hidden;
  border-radius: 1.2rem;
}

.h-1 {
  font-size: 3.6rem;
  letter-spacing: 0.1em;
}
.h-1-en {
  font-size: 1.8rem;
  letter-spacing: 0.1em;
  color: #036835;
  margin: 0.1em 0 4rem;
}
h1.h-1 + .h-1-en {
  margin-bottom: 7.5rem;
}
.h-1.wh,
.h-1-en.wh {
  color: #fff;
}

.h-2 {
  font-size: 3rem;
  margin: 7rem 0 3rem;
}

.f20 {
  font-size: 2rem;
  margin-bottom: 3rem;
}
.f20 .note {
  font-size: 1.4rem;
  font-weight: 400;
  margin-left: 0.3em;
}
.f20 .num {
  color: #036835;
  margin-right: 0.3em;
}

.en {
  letter-spacing: 0.1em;
}

.square {
  padding-left: 1em;
  position: relative;
}
.square::before {
  content: "■";
  display: block;
  font-size: 70%;
  position: absolute;
  left: 0;
  top: 0.35em;
}

.checkbox.col1 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
}
.checkbox.col3,
.checkbox.col4,
.checkbox.col5 {
  width: 100%;
  display: grid;
  gap: 2rem;
}
.checkbox + .checkbox {
  margin-top: 2rem;
}
.checkbox + .checkbox.col1 {
  margin-top: 1rem;
}
.checkbox.col3 {
  grid-template-columns: repeat(3, 1fr);
}
.checkbox.col4 {
  grid-template-columns: repeat(4, 1fr);
}
.checkbox.col5 {
  grid-template-columns: repeat(5, 1fr);
}
.checkbox li {
  display: block;
}

dl.form-dl {
  display: grid;
  grid-template-columns: 23rem 1fr;
  border-bottom: 1px solid #ccc;
}
dl.form-dl dt,
dl.form-dl dd {
  padding: 1.6rem 0;
  border-top: 1px solid #ccc;
}
dl.form-dl dt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;
  line-height: 1.5;
  padding-right: 1.6rem;
  position: relative;
}
dl.form-dl dt.txt-top {
  justify-content: flex-start;
}
dl.form-dl dt.ptb26 {
  padding: 2.6rem 0;
}
dl.form-dl dt.required::after {
  content: "必須";
  display: inline-block;
  line-height: 2.4rem;
  border-radius: 1.2rem;
  padding: 0 0.5em;
  background: #d94724;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
  position: absolute;
  right: 1.6rem;
  top: 50%;
  transform: translateY(-50%);
}
dl.form-dl dt.required.txt-top.ptb26::after {
  top: 2.6rem;
  transform: unset;
}
dl.form-dl dt .note {
  font-size: 1.4rem;
  font-weight: 400;
  opacity: 0.5;
}
dl.form-dl dd .checkbox,
dl.form-dl dd .radio {
  margin: 1rem 0;
}

form input[type="text"],
form input[type="email"],
form input[type="tel"],
form input[type="search"],
form textarea,
.select select {
  display: block;
  width: 100%;
  line-height: 4.8rem;
  padding: 0 1.2rem;
  border-radius: 1.2rem;
  border: none;
  background: #fff;
  color: #21241f;
  font-size: 1.6rem;
}
form textarea {
  height: 30rem;
}
form input.short {
  width: 20rem;
}
.validate,
input[type="checkbox"].validate + span::before {
  border: 2px solid #d94723 !important;
}
form input.gl,
.select.gl select {
  background: #eaeaea;
}
.select select {
  padding-right: 3rem;
}

.checkbox.flex,
.radio.flex {
  display: flex;
  flex-wrap: wrap;
}
.checkbox.flex label + label,
.radio.flex label + label {
  margin-left: 4rem;
}

.checkbox.and-input .wrap input[type="text"] {
  margin-left: calc(2.4rem + 1.6rem);
  margin-top: 1.5rem;
}
.checkbox.and-input .wrap + .wrap {
  margin-top: 2rem;
}

.radio.and-select {
  width: fit-content;
}
.radio.and-select .wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 4.8rem;
}
.radio.and-select .wrap > .select-flex {
  margin-left: 2.4rem;
}
.radio.and-select .wrap + .wrap {
  margin-top: 1.2rem;
}

.checkbox label {
  display: block;
  font-size: 1.6rem;
  position: relative;
}
.checkbox input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}
.checkbox input[type="checkbox"] + span {
  display: inline-block;
  padding-left: calc(2.4rem + 1.6rem);
  line-height: 2.4rem;
  cursor: pointer;
  position: relative;
}
.checkbox input[type="checkbox"] + span::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 2.4rem;
  height: 2.4rem;
  background: #fff;
  border-radius: 0.4rem;
}
.checkbox input[type="checkbox"]:checked + span::before {
  background: #036835;
}
.checkbox input[type="checkbox"]:checked + span::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.85rem;
  transform: rotate(45deg);
  border-bottom: 0.25rem solid #fff;
  border-right: 0.25rem solid #fff;
  position: absolute;
  top: 50%;
  margin-top: -0.7rem;
  left: 0.85rem;
}
.checkbox.gl input[type="checkbox"] + span::before {
  background: #eaeaea;
}
.checkbox.gl input[type="checkbox"]:checked + span::before {
  background: #036835;
}

.radio label {
  display: block;
  font-size: 1.6rem;
  position: relative;
}
.radio input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}
.radio input[type="radio"] + span {
  display: inline-block;
  padding-left: calc(2.4rem + 1.2rem);
  line-height: 2.4rem;
  cursor: pointer;
  position: relative;
}
.radio input[type="radio"] + span::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 2.4rem;
  height: 2.4rem;
  box-sizing: border-box;
  background: #fff;
  border-radius: 50%;
}
.radio input[type="radio"]:checked + span::after {
  content: "";
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  background: #036835;
  border-radius: 50%;
  position: absolute;
  left: 0.6rem;
  top: 50%;
  transform: translateY(-50%);
}
.radio.gl input[type="radio"] + span::before {
  background: #eaeaea;
}

.select-flex {
  display: flex;
  align-items: center;
}
.select-flex > * + * {
  margin-left: 1.2rem;
}

.btn-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4.8rem;
  border-radius: 0.8rem;
  border: 2px solid;
  font-weight: 700;
}

.tag-wrap {
  display: flex;
}
.tag {
  width: 7.4rem;
  line-height: 2.4rem;
  border-radius: 1.2rem;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
}
.tag + .tag {
  margin-left: 0.8rem;
}
.tag.office {
  background: #036835;
}
.tag.store {
  background: #61b92b;
}
.tag.soho {
  background: #afc410;
}

.zoom {
  display: block;
  overflow: hidden;
  will-change: transform;
}
.zoom img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s;
}
a.zoom:hover img,
a:hover .zoom img {
  transform: scale3d(1.11, 1.11, 1.11);
}

.ac-btn {
  cursor: pointer;
}

/* ヘッダー動き */
.t-anime-1,
.t-anime-2,
.t-anime-3,
.t-anime-4 {
  opacity: 0;
  transition:
    opacity 1s,
    transform 1s;
}
.t-anime-1 {
  transform: translateY(2rem);
  transition-delay: 0.5s;
}
.t-anime-2 {
  transform: translateY(2rem);
  transition-delay: 1s;
}
.t-anime-3 {
  transform: scale3d(1.1);
  transition-delay: 1.5s;
}
.t-anime-4 {
  transform: scale3d(1.1);
  transition-delay: 2s;
}
.t-anime-1,
.t-anime-2,
.t-anime-3,
.t-anime-4 {
  transition-property: opacity, transform;
}

.t-anime-1.load,
.t-anime-2.load,
.t-anime-3.load,
.t-anime-4.load {
  opacity: 1;
  transform: translate(0);
}

/* スクロールアニメ */
.fade-b,
.fade-b-ct > * {
  opacity: 0;
  transition:
    opacity 1s,
    transform 1s;
}

.fade-b,
.fade-b-ct > * {
  transform: translateY(3rem);
}

.fade-b.load,
.fade-b-ct.load > * {
  opacity: 1;
  transform: translate(0);
}

.fade-b-ct.load > :nth-child(2) {
  transition-delay: 0.2s;
}
.fade-b-ct.load > :nth-child(3) {
  transition-delay: 0.4s;
}

.fade-b-ct.load > :nth-child(2),
.fade-b-ct.load > :nth-child(3) {
  transition-property: opacity, transform;
}

@media (min-width: 769px) {
  .ct2-pc.load {
    transition-delay: 0.2s;
  }
  .ct3-pc.load {
    transition-delay: 0.4s;
  }

  .ct2-pc.load,
  .ct3-pc.load {
    transition-property: opacity, transform;
  }
}

/* ロード画面 */
#loading {
  width: 100%;
  height: 100%;
  background: #125231;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
#loading img {
  width: 24rem;
  animation: logo-loading 2s infinite;
}

@keyframes logo-loading {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

#loading.loaded {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 1s,
    visibility 1s;
}

/* ページトップ */
#pagetop {
  display: none;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 997;
}
#pagetop a {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 4rem;
  height: 4rem;
  background-color: #115231;
}
#pagetop a img {
  width: 1.6rem;
  margin-top: -0.2rem;
}

/*　PC
------------------------------------------------------------------------------------------------*/
@media (min-width: 769px) {
  .sp {
    display: none !important;
  }

  html {
    font-size: 10px;
  }
  body {
    font-size: 1.6rem;
  }

  .content {
    width: 100%;
    max-width: 124rem;
    padding: 0 4rem;
    margin: 0 auto;
  }

  /*　タブレット
------------------------------------------------------------------------------------------------*/
  @media (max-width: 1240px) {
    html {
      font-size: 0.806vw;
    }
  } /*　タブレットここまで */
} /*　PCここまで */

/*　スマホ
------------------------------------------------------------------------------------------------*/
@media (max-width: 768px) {
  .pc {
    display: none !important;
  }

  html {
    font-size: 2.56vw;
  }
  body {
    font-size: 1.4rem;
  }

  .content {
    width: 100%;
    padding: 0 1.6rem;
  }

  ul.sp-nav1 li {
    font-size: 2rem;
    font-weight: 700;
    padding-left: 2.2rem;
    position: relative;
  }
  ul.sp-nav1 li + li {
    margin-top: 0.6em;
  }
  ul.sp-nav1 li::before {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background: #036835;
    position: absolute;
    left: 0;
    top: 0.6em;
  }

  ul.sp-nav2 {
    margin-top: 3rem;
  }
  ul.sp-nav2 li {
    font-size: 1.6rem;
  }
  ul.sp-nav2 li + li {
    margin-top: 0.6em;
  }

  .common-box {
    padding: 2rem 1.6rem;
  }
  .common-box h2 {
    font-size: 1.6rem;
    margin: 2.5rem 0 1rem;
  }

  ul.pankuzu {
    font-size: 1.2rem;
  }

  .h-1 {
    font-size: 2.4rem;
  }
  .h-1-en {
    font-size: 1.6rem;
    margin: 0 0 3.5rem;
  }
  h1.h-1 + .h-1-en {
    margin-bottom: 5rem;
  }

  .h-2 {
    font-size: 2rem;
    margin: 0 0 2.5rem;
  }

  .f20 {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
  .f20 .note {
    font-size: 1.2rem;
  }

  .checkbox.col1 {
    gap: 2rem;
  }
  .checkbox.col3,
  .checkbox.col4,
  .checkbox.col5 {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0;
  }

  .checkbox.sp-col1-bd {
    grid-template-columns: 1fr;
    gap: unset;
  }
  .checkbox.sp-col1-bd > li > label {
    display: block;
    padding: 1.5rem 1.2rem;
    cursor: pointer;
  }
  .checkbox.sp-col1-bd > li + li {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  .checkbox.sp-col1 {
    grid-template-columns: 1fr;
  }

  dl.form-dl {
    grid-template-columns: 1fr;
  }
  dl.form-dl dt,
  dl.form-dl dt.ptb26 {
    padding: 2rem 0 0.7em !important;
  }
  dl.form-dl dd {
    padding: 0 0 2rem !important;
    border-top: none;
  }
  dl.form-dl dt {
    font-size: 1.6rem;
    display: block;
    padding-right: 0;
  }
  dl.form-dl dt.required::after {
    position: unset;
    transform: unset;
    margin-left: 0.6em;
  }
  dl.form-dl dt .note {
    font-size: 1.2rem;
    margin-left: 0.4em;
  }

  form input.short {
    width: 100%;
  }

  .checkbox.flex,
  .radio.flex {
    display: block;
  }
  .checkbox.flex label + label,
  .radio.flex label + label {
    margin: 2rem 0 0;
  }

  .checkbox.and-input .wrap input[type="text"] {
    margin-left: 0;
  }
  .checkbox.and-input .wrap + .wrap {
    margin-top: 2rem;
  }

  .radio.and-select .wrap {
    display: block;
    min-height: unset;
  }
  .radio.and-select .wrap > .select-flex {
    margin: 1.2rem 0 0;
  }
  .radio.and-select .wrap + .wrap {
    margin-top: 2rem;
  }

  .checkbox input[type="checkbox"] + span {
    padding-left: calc(2.4rem + 1.2rem);
  }

  .radio input[type="radio"] + span {
    padding-left: calc(2.4rem + 1.2rem);
  }
} /*　スマホここまで */
